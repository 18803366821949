import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["closeButton"];

  closeNotification() {
    let notification = this.closeButtonTarget.parentNode;
    notification.parentElement.removeChild(notification);
  }
}
