import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileName"];

  fileChanged(ev) {
    if (ev.target.files.length > 0) {
      this.fileNameTarget.textContent = ev.target.files[0].name;
    }
  }
}
